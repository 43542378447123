import React from 'react'
import { css } from '@emotion/core'

const style = css`
  position: absolute;
  width: 147px;
  height: 46px;
  left: 47px;
  top: 212px;

  font-family: Saira;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 44px;

  color: #454545;
`

export default () => <span css={style}>k3nt0</span>
